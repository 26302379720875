
import { defineComponent, ref, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  renderImageUrl,
  refreshSelectMedia,
  showMultipleMedia,
  showSingleMedia,
  renderImageUrlEditor,
} from "@/core/services/AppService";
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { Actions } from "@/store/enums/StoreEnums";
import { Project, ProjectDefault } from "@/store/model/Project";
import {
  apartment,
  apartmentDefault,
  building,
  buildingDefault,
  locationDefault,
} from "@/store/model";

export default defineComponent({
  name: "edit-project",
  props: ["id"],
  components: { editor: Editor },
  setup(props) {
    const image_editor = ref();
    const image_news = ref();
    const isMultipleMedia = computed(() => store.getters["getIsMultiple"]);

    const media_actions = ref([
      "thumbnail",
      "policy_image",
      "payment_schedule_image",
      "banner.image",
      "galleries",
      "utility.images",
      "utility_area.image",
      "images_apartment",
      "place.image",
      "apartments",
    ]);
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;

    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      height: 700,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            //console.log("message", message, api);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print preview anchor insertdatetime media image",
        "paste code  wordcount table advtable",
      ],
      toolbar:
        "insertfile  |formatselect | bold italic strikethrough underline |backcolor  color | subscript superscript |   alignleft aligncenter alignright alignjustify |  outdent  indent  anchor undo redo | lists   bullist numlist outdent indent | table |image|code|fullscreen",
    };
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const listDataCategory = ref();
    const listDataProvince = ref();
    const listDataDistrict = ref();
    const listDataWard = ref();
    const listDataStreet = ref();

    const objectData = ref<Project>(
      JSON.parse(JSON.stringify(ProjectDefault)) as Project
    );
    let account_current = ref({});

    const disabledDate = (time: Date) => {
      return time.getTime() < Date.now() - 86400000;
    };

    const defaultDate = () => {
      return new Date();
    };

    const addBuilding = () => {
      objectData.value.master_plan.buildings.push(
        JSON.parse(JSON.stringify(buildingDefault)) as building
      );
      onChangeMasterPlan();
    };

    const removeBuilding = (index) => {
      if (index > -1) {
        objectData.value.master_plan.buildings.splice(index, 1);
      }
      onChangeMasterPlan();
    };

    const addApartment = (index) => {
      if (!objectData.value.master_plan.buildings[index].apartments) {
        objectData.value.master_plan.buildings[index].apartments = [];
      }
      objectData.value.master_plan.buildings[index].apartments.push(
        JSON.parse(JSON.stringify(apartmentDefault)) as apartment
      );
      onChangeMasterPlan();
    };

    const removeApartment = (index, apartmentIndex) => {
      if (apartmentIndex > -1 && index > -1) {
        objectData.value.master_plan.buildings[index].apartments.splice(
          apartmentIndex,
          1
        );
        onChangeMasterPlan();
      }
    };
    onBeforeMount(async () => {
      setCurrentPageBreadcrumbs("Chỉnh sửa dự án", ["Dự án"]);
      await store.dispatch("GET_PROJECT_DETAIL", props.id);
      await store.dispatch(
        Actions.GET_ALL_NEWS_CATEGORY,
        '?search={"type":"project"}'
      );
      // await store.dispatch(Actions.GET_ALL_PROVINCE);
      listDataCategory.value = store.getters["listDataCategory"];
      account_current.value = store.getters["currentUser"];
      objectData.value = await store.getters["currentProject"];
      onChangeMasterPlan();
      await AppService.getDataProvince("")
        .then(async (res: any) => {
          listDataProvince.value = res;
        })
        .catch((error) => {
          console.error(error);
        });
      if (objectData.value.location) {
        if (objectData.value.location.province_id) {
          await AppService.getDataProvince(
            objectData.value.location.province_id
          )
            .then(async (res: any) => {
              listDataDistrict.value = res;
            })
            .catch((error) => {
              console.error(error);
            });

          if (objectData.value.location.district_id) {
            await AppService.getDataProvince(
              objectData.value.location.district_id
            )
              .then(async (res: any) => {
                listDataWard.value = res;
              })
              .catch((error) => {
                console.error(error);
              });
            await AppService.getDataStreet(
              objectData.value.location.district_id
            )
              .then(async (res: any) => {
                listDataStreet.value = res;
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      } else {
        objectData.value.location = locationDefault;
      }
    });
    const onChangeLocation = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataDistrict.value = res;
          objectData.value.location.district_id = null;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });

      let province = listDataProvince.value.filter(
        (item) => item._id === objectData.value.location.province_id
      );
      objectData.value.location.province_name = province[0].name;
    };
    const onChangeDistrict = (value) => {
      AppService.getDataProvince(value)
        .then(async (res: any) => {
          listDataWard.value = res;
          objectData.value.location.ward_id = null;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      AppService.getDataStreet(value)
        .then(async (res: any) => {
          listDataStreet.value = res;
          objectData.value.location.street_id = null;
        })
        .catch((error) => {
          console.error(error);
        });
      let district = listDataDistrict.value.filter(
        (item) => item._id === objectData.value.location.district_id
      );
      objectData.value.location.district_name = district[0].name;
    };
    const onChangeWard = (value) => {
      let ward = listDataWard.value.filter(
        (item) => item._id === objectData.value.location.ward_id
      );
      objectData.value.location.ward_name = ward[0].name;
    };
    const onChangeStreet = (value) => {
      let street = listDataStreet.value.filter(
        (item) => item._id === objectData.value.location.street_id
      );
      objectData.value.location.street_name = street[0].name;
    };
    const message = "Vui lòng không để trống";
    const required_select = {
      required: true,
      message: message,
      trigger: "change",
    };

    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên dự án không được để trống",
          trigger: "blur",
        },
      ],
      slug: [
        {
          required: true,
          message: "Đường đẫn dự án không được để trống",
          trigger: "blur",
        },
      ],
      categories: [
        {
          required: true,
          message: "Danh mục dự án không được để trống",
          trigger: ["blur", "change"],
        },
      ],
      published_at: [
        {
          required: true,
          message: "Ngày đăng tin không được để trống",
          trigger: "blur",
        },
      ],
      "location.province_id": [required_select],
      "location.district_id": [required_select],
      "location.ward_id": [required_select],
    });
    const changeImageFeature = () => {
      let url = "/media?type=image-feature";
      getTinymce().activeEditor.windowManager.openUrl({
        url: url,
        title: "Quản lý media",
        width: x * 0.8,
        height: y * 0.95,
        onClose: () => {
          //console.log("onClose:", image_editor.value);
        },
        onAction: function (instance, trigger) {
          // code for when "custom" buttons are triggered
          //console.log("instance", instance, trigger);
        },
      });
    };
    const convertNameToSlug = (value) => {
      objectData.value["slug"] = AppService.convertSlug(value);
    };
    const selectImageMultiple = () => {
      let url = "/media?type=multi-select";
      getTinymce().activeEditor.windowManager.openUrl({
        url: url,
        title: "Quản lý media",
        width: x * 0.8,
        height: y * 0.95,
        onClose: () => {
          //console.log("onClose:", image_editor.value);
        },
        onAction: function (instance, trigger) {
          // code for when "custom" buttons are triggered
          //console.log("instance", instance, trigger);
        },
      });
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          // if (objectData.value.location.street_id == "") {
          //   objectData.value.address =
          //     objectData.value.location.ward_name +
          //     ", " +
          //     objectData.value.location.district_name +
          //     ", " +
          //     objectData.value.location.province_name;
          // } else {
          //   objectData.value.address =
          //     objectData.value.location.street_name +
          //     ", " +
          //     objectData.value.location.ward_name +
          //     ", " +
          //     objectData.value.location.district_name +
          //     ", " +
          //     objectData.value.location.province_name;
          // }
          // loading.value = true;
          objectData.value["publish_by"] = account_current.value["_id"];
          objectData.value["active"] =
            objectData.value["active"] == "active" || objectData.value["active"]
              ? "active"
              : "inactive";
          //console.log(objectData.value);
          store
            .dispatch("UPDATE_PROJECT", objectData.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông dự án thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(async function (result) {
                if (result.isConfirmed) {
                  await router.push({ name: "projects" });
                } else {
                  let data = await store.getters["currentProject"];
                  await router.push({
                    name: "projects-edit",
                    params: { id: data._id },
                  });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    const onChangePriceMin = (value) => {
      if (value < 0 || value.toString() == "") {
        objectData.value.price_min = 0;
      } else {
        if (value > objectData.value.price_max) {
          objectData.value.price_max = value;
        }
      }
    };
    const onChangePriceMax = (value) => {
      if (value < 0 || value.toString() == "") {
        objectData.value.price_max = 0;
        objectData.value.price_min = 0;
      } else {
        if (value < objectData.value.price_min) {
          objectData.value.price_min = value;
        }
      }
    };
    const onChangeMasterPlan = () => {
      //console.log("onChangeMasterPlan");

      let media_actions_ = [
        "thumbnail",
        "policy_image",
        "payment_schedule_image",
        "banner.image",
        "galleries",
        "utility.images",
        "utility_area.image",
        "images_apartment",
        "place.image",
      ];
      objectData.value.master_plan.buildings.forEach((item, index) => {
        if (item.apartments) {
          item.apartments.forEach((aprtment, key) => {
            media_actions_.push(
              "master_plan.buildings." + index + ".apartments." + key + ".image"
            );
          });
        }
      });
      media_actions.value = media_actions_;
    };
    watch(
      () => store.state.MediaModule.actionCallback,
      (action: any, second) => {
        if (media_actions.value.includes(action)) {
          let fields = action.split(".");
          switch (fields.length) {
            case 1:
              if (isMultipleMedia.value) {
                objectData.value[action] = objectData.value[action].concat(
                  store.getters["getSelectMultiMedia"]
                );
              } else {
                objectData.value[action] = store.getters["getSelectMedia"];
              }
              break;
            case 2:
              if (isMultipleMedia.value) {
                //console.log("fields[0]][fields[1]", fields[0], fields[1]);
                objectData.value[fields[0]][fields[1]] = objectData.value[
                  fields[0]
                ][fields[1]].concat(store.getters["getSelectMultiMedia"]);
              } else {
                objectData.value[fields[0]][fields[1]] =
                  store.getters["getSelectMedia"];
              }
              break;
            case 6:
              if (isMultipleMedia.value) {
                objectData.value[fields[0]][fields[1]][fields[2]][fields[3]][
                  fields[4]
                ][fields[5]] = store.getters["getSelectMultiMedia"];
              } else {
                objectData.value[fields[0]][fields[1]][fields[2]][fields[3]][
                  fields[4]
                ][fields[5]] = store.getters["getSelectMedia"];
              }
              break;
            default:
              break;
          }
          refreshSelectMedia();
        }
      }
    );
    const removeImages = (action) => {
      let fields = action.name.split(".");
      if (fields.length > 1) {
        objectData.value[fields[0]][fields[1]].splice(action.index, 1);
      } else {
        objectData.value[fields[0]].splice(action.index, 1);
      }
    };

    const checkDefaultImage = (image) => {
      if (image.name === "default-placeholder.jpg") {
        return false;
      } else {
        return true;
      }
    };

    return {
      rules,
      submit,
      editorConfig,
      changeImageFeature,
      removeImages,
      checkDefaultImage,
      selectImageMultiple,
      convertNameToSlug,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
      listDataCategory,
      formRef,
      image_editor,
      image_news,
      listDataProvince,
      listDataDistrict,
      listDataWard,
      listDataStreet,
      onChangeLocation,
      onChangeDistrict,
      onChangeWard,
      onChangeStreet,
      onChangePriceMax,
      onChangePriceMin,
      disabledDate,
      defaultDate,
      addBuilding,
      addApartment,
      removeBuilding,
      removeApartment,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
        //console.log("data received editor:  ", event.data);
      }
      if (event.data.action == "image-feature") {
        this.objectData["image"] = JSON.parse(event.data.image);
        this.image_news = JSON.parse(event.data.image);
        //console.log("image-feature:  ", event.data.image);
      }
    },
  },
});
